import React from 'react';
import * as styles from './Sidebar.module.scss';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import { useSiteMetadata } from '../../hooks';

type Props = {
   isIndex: boolean;
};

const Sidebar = ({ isIndex }: Props) => {
   const { author, copyright } = useSiteMetadata();

   return (
      <div className={styles['sidebar']}>
         <div className={styles['sidebar__inner']}>
            <Author author={author} isIndex={isIndex} />
            <Menu />
            <Contacts contacts={author.contacts} />
            <Copyright copyright={copyright} />
         </div>
      </div>
   );
};

export default Sidebar;
