import React from 'react';
import { graphql } from 'gatsby';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';

type Props = {
   data: {
      markdownRemark: MarkdownRemark;
   };
};

const PageTemplate = ({ data }: Props) => {
   const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
   const { html: pageBody } = data.markdownRemark;
   const { frontmatter } = data.markdownRemark;
   const metaDescription = frontmatter.description || siteSubtitle;

   return (
      <Layout
         title={`${frontmatter.title} - ${siteTitle}`}
         description={metaDescription}
         socialImage={frontmatter.socialImage?.publicURL}
      >
         <Sidebar isIndex={false} />
         <Page title={frontmatter.title}>
            <div dangerouslySetInnerHTML={{ __html: pageBody }} />
         </Page>
      </Layout>
   );
};

export const query = graphql`
   query PageBySlug($slug: String!) {
      markdownRemark(fields: { slug: { eq: $slug } }) {
         id
         html
         frontmatter {
            title
            date
            description
            socialImage {
               publicURL
            }
         }
      }
   }
`;

export default PageTemplate;
