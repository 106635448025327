import React from 'react';
import { Link } from 'gatsby';
import * as styles from './Menu.module.scss';
import { useResumeFile } from '../../../hooks';

// import resumePDF from '../2021_Zhongyuan_Resume.pdf';

type MenuLinkProps = {
   label: string;
   path: string;
};

const MenuLink = ({ label, path }: MenuLinkProps) => {
   return (
      <li className={styles['menu__list_item']} key={path}>
         <Link
            to={path}
            className={styles['menu__list_item_link']}
            activeClassName={styles['menu__list_item_link__active']}
         >
            {label}
         </Link>
      </li>
   );
};

const Menu = () => {
   const resumeLink: string = useResumeFile() || '/';

   return (
      <nav className={styles['menu']}>
         <ul className={styles['menu__list']}>
            <MenuLink label="Articles" path="/" />
            <MenuLink label="About me" path="/pages/about" />
            <li className={styles['menu__list_item']}>
               <a
                  className={styles['menu__list_item_link']}
                  href={resumeLink}
                  target="_blank"
                  rel="noreferrer"
               >
                  Resume
               </a>
            </li>
         </ul>
      </nav>
   );
};

export default Menu;
